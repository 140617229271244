
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import Cell from './cell'
  import { fixPrice } from '@/utils/general'

  import { ClosingReason, Icon, ProcessStatus } from '@/entities/settings'
  import { Auto } from '@/entities/public'
  import { Appraisal, Enablement, Inspection, Negotiation, PurchaseOrder } from '@/entities/purchase'
  import { Evaluation } from '@/entities/loans'
  import { Reserve, SaleOrder } from '@/entities/sales'
  import { Document } from '@/entities/documents'
  import { ExpenseOrder, Payment } from '@/entities/finance'

@Component
  export default class StatusCell extends Cell {
  @Prop({ type: String, default: 'small' }) size!: string;
  @Prop({ type: String, default: 'space-between' }) justify!: string;
  @Prop({ type: Number, default: 10 }) cols!: string;

  showDetails = true
  declare options: {
    set: Record<string, Icon>
  }

  get status (): ProcessStatus | ClosingReason {
    const { item } = this

    if (!item) return

    if ((
      (item instanceof Evaluation && item.status.isClosed) ||
      item instanceof Negotiation ||
      item instanceof Inspection ||
      item instanceof Appraisal ||
      item instanceof SaleOrder ||
      item instanceof PurchaseOrder ||
      item instanceof Document ||
      item instanceof Reserve ||
      item instanceof Payment ||
      item instanceof ExpenseOrder ||
      item instanceof Enablement) && item?.closingReason
    ) {
      return item?.closingReason
    }

    const isEvaluation = item instanceof Evaluation || 'evaluation' in item
    if ((item?.status?.higherClosingReason || isEvaluation) && item?.status?.isClosed) {
      return item?.closingReason || item?.evaluation?.closingReason
    }

    return item.status
  }

  private stockPrice () {
    const { price } = this.item as Auto
    return fixPrice(price)
  }

  private appraisalPrice () {
    const { price } = this.item as Appraisal
    return fixPrice(price)
  }

  get price () {
    if (this.item instanceof Auto) return this.stockPrice()
    if (this.item instanceof Appraisal) return this.appraisalPrice()
  }

  get financing () {
    const { agreement } = this.item
    if (!agreement?.person) return null
    const { person: { alias } } = agreement

    if (!alias) return null

    return alias
  }

  get company () {
    const { company } = this.item
    if (!company) return undefined
    const { alias } = company
    if (!alias) return undefined
    // if (!(this.item instanceof Financing)) return
    //
    // const { company: { alias } } = this.item as Financing
    // return alias
    return alias
  }

  private stockExecutive () {
    const { isAvailable, price } = this.item as Auto
    if (isAvailable) return

    return price
  }

  get executive () {
    if (this.item instanceof Auto) return this.stockExecutive()
  }

  private soldDate () {
    const { isAvailable, date } = this.item as Auto
    if (isAvailable) return

    return date
  }

  get date () {
    if (this.item instanceof Auto) return this.soldDate()
  }

  get isSmall () {
    const { size, isMobile } = this

    if (isMobile) return false

    return size === 'small'
  }

  @Watch('options', { immediate: true, deep: true })
  onShowDetailsChange (options) {
    if (options && 'showDetails' in options) {
      this.showDetails = options.showDetails
    }
  }
  }
